import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EnvConfig, ENV_CONFIG } from '@beneficity/environment/types';
import { BaseExternalHttpClient } from '../external-clients';

/**
 * Oauth Login Http Client can be injected into any
 * public services that are exposed via OAuth Login
 * This custom client provides the proper serverURL.
 * https://gitlabhealth.highmark.com/atlas_labs/community/diginn-docs/blob/master/resources/member-portal/rest-services/base-http-client.md
 */
@Injectable({
  providedIn: 'root',
})
export class LoginHttpClient extends BaseExternalHttpClient {
  constructor(
    protected http: HttpClient,
    @Inject(ENV_CONFIG) public envConfig: EnvConfig
  ) {
    super(http, envConfig);
    this.serverURL = `${this.envConfig.serviceDomains.protocol}${this.envConfig.serviceDomains.login}`;
  }
}
