import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRestService, ServiceProperties } from '@beneficity/shared/types';
import { LoginHttpClient } from '@beneficity/shared/util';

@Injectable()
export class OamTokenAuthService implements BaseRestService {
  serviceProperties: ServiceProperties;

  constructor(private readonly http: LoginHttpClient) {
    this.serviceProperties = {
      url: '/oam/server/authentication',
    };
  }

  fetch(accessToken: string): Observable<any> {
    return this.http.post(
      this.serviceProperties,
      null,
      {
        jwttoken: accessToken,
        'Content-Type': 'text/html',
        Accept: 'text/html',
      },
      {
        successurl: `${this.http.serverURL}/token.html`,
        type: 'jwt',
      },
      null,
      'text'
    );
  }
}
